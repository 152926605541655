import React from 'react'
import ParticlesBg from 'particles-bg'
import './Pages.css'
import FlipCard from '../components/Card/FlipCard'

const test = () => {
	return (
		<>
            <div id="particles-js"></div>
        </>
    )
}

export default test